var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "lucky-modal modal-page",
      class: { active: _vm.ready },
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.dismiss($event)
        }
      }
    },
    [
      _c("div", { staticClass: "hold-in-center" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", {
            staticClass: "image",
            attrs: { type: _vm.type, level: _vm.level }
          }),
          _c("p", { staticClass: "title" }, [_vm._v("ขอแสดงความยินดี")]),
          _vm.type == "normal_date"
            ? _c("p", { staticClass: "description" }, [
                _vm._v(" คุณได้รับ 1 เหรียญ! ")
              ])
            : _vm.type == "special_date"
            ? _c("p", { staticClass: "description" }, [
                _vm._v(
                  " คุณได้รับ " +
                    _vm._s(_vm._f("numberWithCommas")(_vm.points)) +
                    " คะแนน "
                )
              ])
            : _vm.type == "immediate_point"
            ? _c("p", { staticClass: "description" }, [
                _vm._v(
                  " คุณได้รับ " +
                    _vm._s(_vm._f("numberWithCommas")(_vm.points)) +
                    " คะแนน "
                )
              ])
            : _vm.type == "coin_exchange"
            ? _c("p", { staticClass: "description" }, [
                _vm._v(" หีบที่ " + _vm._s(_vm.level) + " ได้ถูกเปิดแล้ว"),
                _c("br"),
                _vm._v(
                  " เหรียญของคุณแลกได้ " +
                    _vm._s(_vm._f("numberWithCommas")(_vm.points)) +
                    " คะแนน "
                )
              ])
            : _vm.type == "coin_exchange_auto"
            ? _c("p", { staticClass: "description" }, [
                _vm._v(" หีบที่ " + _vm._s(_vm.level) + " ได้ถูกเปิดแล้ว"),
                _c("br"),
                _vm._v(
                  " เหรียญของคุณแลกได้ " +
                    _vm._s(_vm._f("numberWithCommas")(_vm.points)) +
                    " คะแนน "
                )
              ])
            : _vm.type == "coin_exchange_by_quiz"
            ? _c("p", { staticClass: "description" }, [
                _vm._v(" หีบที่ " + _vm._s(_vm.level) + " ได้ถูกเปิดแล้ว"),
                _c("br"),
                _vm._v(
                  " เหรียญของคุณแลกได้ " +
                    _vm._s(_vm._f("numberWithCommas")(_vm.points)) +
                    " คะแนน "
                )
              ])
            : _vm._e(),
          _vm.type == "special_date"
            ? _c(
                "button",
                { staticClass: "btn btn-primary", on: { click: _vm.unlock } },
                [_vm._v(" ตกลง ")]
              )
            : _vm.type == "coin_exchange"
            ? _c(
                "button",
                { staticClass: "btn btn-primary", on: { click: _vm.exchange } },
                [_vm._v(" ตกลง ")]
              )
            : _vm.type == "immediate_point"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.onCloseImmediatePopup }
                },
                [_vm._v(" ตกลง ")]
              )
            : _vm.type == "coin_exchange_auto"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.exchangeAuto }
                },
                [_vm._v(" ตกลง ")]
              )
            : _vm.type == "coin_exchange_by_quiz"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.exchangeQuiz }
                },
                [_vm._v(" ตกลง ")]
              )
            : _c(
                "button",
                { staticClass: "btn btn-primary", on: { click: _vm.close } },
                [_vm._v("ตกลง")]
              )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }