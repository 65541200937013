var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _vm.isCampaignActive
      ? _c(
          "div",
          [
            _vm.isEnableImmediatePoints
              ? _c("LandingPage")
              : _c("div", [
                  _c("div", { staticClass: "navbar" }, [
                    _c("span", [_vm._v("จำนวนเหรียญสะสม:")]),
                    _c("div", { staticClass: "coin-counter" }, [
                      _c("div", { staticClass: "number" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("numberWithCommas")(_vm.userCoins)) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "coin" })
                    ])
                  ]),
                  _vm.isEnableCoinExchange
                    ? _c("div", { staticClass: "coin-exchange" }, [
                        _c("div", { staticClass: "block" }, [
                          _c("div", { staticClass: "mini" }, [
                            _c("span", [_vm._v("Level 1")]),
                            _c("div", { staticClass: "coin-counter" }, [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("numberWithCommas")(
                                        _vm.fromCoins(0)
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("div", { staticClass: "coin" })
                            ])
                          ]),
                          _c("div", { staticClass: "thumb" }, [
                            _c("div", {
                              staticClass: "level level-1",
                              attrs: { state: _vm.getChestStatus(0) }
                            }),
                            _c("div", {
                              staticClass: "bg",
                              class: { active: _vm.isActiveChestBase(0) }
                            })
                          ]),
                          _c("div", { staticClass: "point" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("numberWithCommas")(_vm.toPoints(0))
                                ) +
                                " คะแนน "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "block" }, [
                          _c("div", { staticClass: "mini" }, [
                            _c("span", [_vm._v("Level 2")]),
                            _c("div", { staticClass: "coin-counter" }, [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("numberWithCommas")(
                                        _vm.fromCoins(1)
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("div", { staticClass: "coin" })
                            ])
                          ]),
                          _c("div", { staticClass: "thumb" }, [
                            _c("div", {
                              staticClass: "level level-2",
                              attrs: { state: _vm.getChestStatus(1) }
                            }),
                            _c("div", {
                              staticClass: "bg",
                              class: { active: _vm.isActiveChestBase(1) }
                            })
                          ]),
                          _c("div", { staticClass: "point" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("numberWithCommas")(_vm.toPoints(1))
                                ) +
                                " คะแนน "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "block" }, [
                          _c("div", { staticClass: "mini" }, [
                            _c("span", [_vm._v("Level 3")]),
                            _c("div", { staticClass: "coin-counter" }, [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("numberWithCommas")(
                                        _vm.fromCoins(2)
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("div", { staticClass: "coin" })
                            ])
                          ]),
                          _c("div", { staticClass: "thumb" }, [
                            _c("div", {
                              staticClass: "level level-3",
                              attrs: { state: _vm.getChestStatus(2) }
                            }),
                            _c("div", {
                              staticClass: "bg",
                              class: { active: _vm.isActiveChestBase(2) }
                            })
                          ]),
                          _c("div", { staticClass: "point" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("numberWithCommas")(_vm.toPoints(2))
                                ) +
                                " คะแนน "
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "calendar" },
                    [
                      _c("vue-calendar", {
                        ref: "calendar",
                        staticClass: "disable-dbl-tap-zoom",
                        attrs: {
                          events: _vm.history,
                          disable: _vm.disabledDays,
                          "show-limit": 1,
                          height: "400px"
                        }
                      })
                    ],
                    1
                  )
                ]),
            _c(
              "transition",
              {
                attrs: {
                  "enter-active-class": _vm.transitionEnterActiveClass,
                  "leave-active-class": _vm.transitionLeaveActiveClass
                }
              },
              [
                _vm.displayLuckyModal
                  ? _c("Modal", {
                      attrs: {
                        level: _vm.level,
                        points: _vm.points,
                        type: _vm.modalType
                      },
                      on: {
                        close: _vm.hideModal,
                        exchangeAuto: _vm.exchangeAuto,
                        exchangeQuiz: _vm.exchangeQuiz,
                        unlock: _vm.getPointSpecialDate,
                        exchange: _vm.exchangeCoinsToPoints,
                        onCloseImmediatePopup: _vm.onCloseImmediatePopup
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "transition",
              {
                attrs: {
                  "enter-active-class": "animated bounceIn",
                  "leave-active-class": "animated bounceOut"
                }
              },
              [
                _vm.displayQuizModal
                  ? _c("QuizModal", {
                      attrs: { quiz: _vm.quiz },
                      on: { correct: _vm.onCorrect }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _c("div", { staticClass: "no-active-campaign-found" }, [
          _c("p", [_vm._v("ขออภัย กิจกรรม Daily Login ยังไม่เริ่ม")])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }