var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNewLogin || !_vm.campaign.link
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "content" }, [
          _c("h2", {
            staticClass: "heading",
            domProps: { innerHTML: _vm._s(_vm.campaign.text1) }
          }),
          _c("button", { on: { click: _vm.redirect } }, [
            _vm._v(
              " วันนี้คุณได้รับ " +
                _vm._s(_vm.campaign.immediate_points) +
                " คะแนนแล้ว "
            )
          ]),
          _c("p", { staticClass: "bold" }, [_vm._v(_vm._s(_vm.campaign.text2))])
        ])
      ])
    : _c("div", { staticClass: "page-loading bg-blue" }, [
        _c("p", [_vm._v("Loading...")])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }