var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quiz-modal modal-page", class: { active: _vm.ready } },
    [
      _c("div", { staticClass: "container hold-in-center" }, [
        _c("div", { staticClass: "header" }, [
          _vm._v("กรุณาตอบคำถาม ก่อนรับสิทธิ์เปิดหีบ")
        ]),
        _c("div", { staticClass: "image-video" }, [
          _vm.hasImage
            ? _c("img", { attrs: { src: _vm.quiz.image_url } })
            : _vm.hasVideo
            ? _c("div", { staticClass: "video-container" }, [
                _c("iframe", {
                  staticClass: "responsive-iframe",
                  attrs: { src: _vm.quiz.video_url }
                })
              ])
            : _vm._e()
        ]),
        _c("h4", { staticClass: "question" }, [_vm._v(_vm._s(_vm.question))]),
        _vm.showError
          ? _c("p", { staticClass: "error-message" }, [
              _vm._v("ลองตอบคำถามใหม่อีกครั้ง")
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "choice-list", class: { error: _vm.showError } },
          _vm._l(4, function(n) {
            return _c(
              "div",
              {
                key: n,
                staticClass: "choice",
                class: {
                  selected: +_vm.selected === +n,
                  "is-empty-null": _vm.isEmptyOrNullText(n)
                }
              },
              [
                _vm.quiz["choice" + n] ? _c("div") : _vm._e(),
                _c("label", { staticClass: "radio radio-gradient" }, [
                  _c("span", { staticClass: "radio__input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selected,
                          expression: "selected"
                        }
                      ],
                      attrs: { type: "radio", name: "radio" },
                      domProps: { value: n, checked: _vm._q(_vm.selected, n) },
                      on: {
                        change: function($event) {
                          _vm.selected = n
                        }
                      }
                    }),
                    _c("span", { staticClass: "radio__control" })
                  ]),
                  _c("span", { staticClass: "radio__label" }, [
                    _vm._v(" " + _vm._s(_vm.quiz["choice" + n]))
                  ])
                ])
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: _vm.selected === null },
              on: { click: _vm.submit }
            },
            [_vm._v(" ยืนยัน ")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }