import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

import user from "@/store/user";
import calendar from "@/store/calendar";

const debug = process.env.NODE_ENV !== "production";

const vuexLocal = new VuexPersistence({ storage: window.sessionStorage });

export default new Vuex.Store({
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    user: user,
    calendar: calendar,
  },
  plugins: [vuexLocal.plugin],

  // https://github.com/championswimmer/vuex-persist#support-strict-mode
  strict: debug,
});
