import Vue from "vue";
import { DateTime } from "luxon";

const toValidDate = (value) => {
  try {
    return DateTime.isDateTime(value)
      ? value
      : new Date(value.replace(/-/g, "/")).toISOString();
  } catch (error) {
    return new Date();
  }
};

Vue.filter("numberWithCommas", function(value) {
  try {
    let parts = value.toString().split(".");
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "")
    );
  } catch (error) {
    return "-";
  }
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("toLowerCase", function(value) {
  if (!value) return "";
  return value.toLowerCase();
});

Vue.filter("toUpperCase", function(value) {
  if (!value) return "";
  return value.toUpperCase();
});

Vue.filter("camelize", function(value) {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
});

Vue.filter("date", function(value) {
  return DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATE_FULL);
});

Vue.filter("date_med", function(value) {
  return DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATE_MED);
});

Vue.filter("dateTimeShort", function(value) {
  return DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATETIME_SHORT);
});

Vue.filter("DATE_SHORT", function(value) {
  return DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATE_SHORT);
});

Vue.filter("TIME_24_WITH_SECONDS", function(value) {
  return DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.TIME_24_WITH_SECONDS);
});

Vue.filter("dateTimeMedium", function(value) {
  return DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATETIME_MED);
});

Vue.filter("dateTime", function(value) {
  return DateTime.fromISO(toValidDate(value))
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATETIME_FULL);
});

Vue.filter("dateTimeFromTimestamp", function(value) {
  return DateTime.fromMillis(value)
    .setLocale("th-TH")
    .toLocaleString(DateTime.DATETIME_SHORT);
});

Vue.filter("audienceStatusText", function(value) {
  return value ? "พร้อมดาว์นโหลด" : "กำลังสร้าง";
});

Vue.filter("genderTH", function(value) {
  let genderTH = "-";
  switch (value) {
    case "male":
      genderTH = "ชาย";
      break;
    case "female":
      genderTH = "หญิง";
      break;
  }
  return genderTH;
});
