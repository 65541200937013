<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getAccesToken } from "@/api/http";

export default {
  name: "App",

  async created() {
    this.init();
  },

  updated() {
    if (this.calendarInfo != null) {
      setTimeout(this.gotoHomePage, 500);
    }
  },

  computed: {
    ...mapGetters({ calendarInfo: "calendar/calendarInfo" }),
  },

  watch: {
    calendarInfo(v) {
      if (v !== null) {
        setTimeout(this.gotoHomePage, 500);
      }
    },
  },

  methods: {
    ...mapActions({
      checkin: `calendar/checkin`,
      setAccessToken: `user/setAccessToken`,
    }),

    async init() {
      try {
        let QUERY = "";

        switch (process.env.NODE_ENV) {
          case "development":
            QUERY = process.env.VUE_APP_SALEFORCE_QUERY;
            break;

          default:
            QUERY = this.$route.query.QUERY || this.$route.query.query;
            break;
        }

        const { token, user } = await getAccesToken(QUERY);
        this.setAccessToken(token);

        await this.checkin();
      } catch (error) {
        console.log(error);
      }
    },

    gotoHomePage() {
      this.$router.push({ name: "Home" }).catch((err) => err);
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/v-slim-dialog/dist/v-slim-dialog";
@import "@/styles/app.scss";
</style>
