import axios from "axios";

function getQueryString() {
  var qd = {};

  if (location.search)
    location.search
      .substr(1)
      .split("&")
      .forEach(function(item) {
        var s = item.split("="),
          k = s[0],
          v = s[1] && decodeURIComponent(s[1]); //  null-coalescing / short-circuit
        (qd[k] = qd[k] || []).push(v); // null-coalescing / short-circuit
      });

  return qd;
}

export async function getAccesToken(QUERY) {
  var qd = getQueryString();

  const form = {};

  if ("QUERY" in qd) {
    form["QUERY"] = QUERY;
  }

  if ("query" in qd) {
    form["query"] = QUERY;
  }

  const response = await axios.post(
    `${process.env.VUE_APP_BASE_API_URL}/dailylogin/auth/login`,
    form,
    {
      headers: {
        "x-application-secret-key": process.env.VUE_APP_SECRET_KEY,
      },
    }
  );

  return response.data;
}

// PMI Mini VIVI Auction api
const amsapi = axios.create({
  timeout: 120000,
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

amsapi.interceptors.request.use(function(config) {
  // Do something before request is sent
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return config;
});

// Add a response interceptor
amsapi.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.response.status === 401) {
      console.error(`Unauthorized`);
    }
    return Promise.reject(error);
  }
);

export async function checkin() {
  return await amsapi.post(`/dailylogin/api/checkin`);
}

export async function exchange(level) {
  return await amsapi.post(`/dailylogin/api/exchange/${level}`);
}

export async function getUserInfo() {
  return await amsapi.get(`/sfdc/api/user/info`);
}
