import { getUserInfo } from "@/api/http";

export default {
  namespaced: true,

  state: {
    accessToken: "",
    userInfo: null,
  },

  getters: {
    accessToken(state) {
      return state.accessToken;
    },
    userInfo(state) {
      return state.userInfo;
    },
  },

  actions: {
    setAccessToken({ commit }, data) {
      commit("SET_TOKEN", data);
      localStorage.setItem("accessToken", data);
    },
    async fetchUserInfo({ commit }) {
      const { data } = await getUserInfo();
      commit("SET_USER_INFO", data);
    },
  },

  mutations: {
    SET_TOKEN(state, payload) {
      state.accessToken = payload;
    },
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
    },
  },
};
