import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VConsole from "vconsole";

import "./filters";

import VueGtag from "vue-gtag";
import SlimDialog from "v-slim-dialog";
import vueCalendar from "vue2-simple-calendar";

import CoinIcon from "@/assets/svg/coin.svg";
import ChestBaseIcon from "@/assets/svg/chest_base.svg";
import ChestBaseAltIcon from "@/assets/svg/chest_base_alt.svg";

import "@/styles/vendors/vue2-simple-calendar.css";

// use the config with languages
import { config as calendarConfig } from "@/config/calendar";
Vue.use(vueCalendar, calendarConfig);
Vue.use(SlimDialog);

if (process.env.NODE_ENV === "development") {
  new VConsole();
}

Vue.component("CoinIcon", CoinIcon);
Vue.component("ChestBaseIcon", ChestBaseIcon);
Vue.component("ChestBaseAltIcon", ChestBaseAltIcon);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
