// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/landing-page-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wrapper[data-v-1c0d9c08] {\n  height: calc(100vh - 100px);\n  background-repeat: no-repeat;\n  background-position: top center;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.wrapper .content[data-v-1c0d9c08] {\n    top: 10rem;\n    position: relative;\n    text-align: center;\n}\n.wrapper .content .heading[data-v-1c0d9c08] {\n      font-size: 32px;\n      padding: 0px 3rem;\n      font-weight: bold;\n}\n.wrapper .content button[data-v-1c0d9c08] {\n      border: none;\n      font-size: 24px;\n      padding: 6px 24px;\n      border-radius: 8px;\n      margin-bottom: 1rem;\n      color: #007aff;\n      background-color: white;\n}\n.wrapper .content p[data-v-1c0d9c08] {\n      line-height: 1;\n      font-weight: bold;\n      padding: 0px 3rem;\n      margin-bottom: 0.5rem;\n}\n.wrapper .content p.bold[data-v-1c0d9c08] {\n        font-weight: bold;\n}\n", ""]);
// Exports
module.exports = exports;
