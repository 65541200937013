<template>
  <div
    class="lucky-modal modal-page"
    :class="{ active: ready }"
    @click.self="dismiss"
  >
    <div class="hold-in-center">
      <div class="container">
        <div class="image" :type="type" :level="level"></div>
        <p class="title">ขอแสดงความยินดี</p>

        <p v-if="type == 'normal_date'" class="description">
          คุณได้รับ 1 เหรียญ!
        </p>
        <p v-else-if="type == 'special_date'" class="description">
          คุณได้รับ {{ points | numberWithCommas }} คะแนน
        </p>
        <p v-else-if="type == 'immediate_point'" class="description">
          คุณได้รับ {{ points | numberWithCommas }} คะแนน
        </p>
        <p v-else-if="type == 'coin_exchange'" class="description">
          หีบที่ {{ level }} ได้ถูกเปิดแล้ว<br />
          เหรียญของคุณแลกได้ {{ points | numberWithCommas }} คะแนน
        </p>
        <p v-else-if="type == 'coin_exchange_auto'" class="description">
          หีบที่ {{ level }} ได้ถูกเปิดแล้ว<br />
          เหรียญของคุณแลกได้ {{ points | numberWithCommas }} คะแนน
        </p>
        <p v-else-if="type == 'coin_exchange_by_quiz'" class="description">
          หีบที่ {{ level }} ได้ถูกเปิดแล้ว<br />
          เหรียญของคุณแลกได้ {{ points | numberWithCommas }} คะแนน
        </p>
        <button
          v-if="type == 'special_date'"
          class="btn btn-primary"
          @click="unlock"
        >
          ตกลง
        </button>
        <button
          v-else-if="type == 'coin_exchange'"
          class="btn btn-primary"
          @click="exchange"
        >
          ตกลง
        </button>
        <button
          v-else-if="type == 'immediate_point'"
          class="btn btn-primary"
          @click="onCloseImmediatePopup"
        >
          ตกลง
        </button>
        <button
          v-else-if="type == 'coin_exchange_auto'"
          class="btn btn-primary"
          @click="exchangeAuto"
        >
          ตกลง
        </button>
        <button
          v-else-if="type == 'coin_exchange_by_quiz'"
          class="btn btn-primary"
          @click="exchangeQuiz"
        >
          ตกลง
        </button>
        <button v-else class="btn btn-primary" @click="close">ตกลง</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LuckyModal",

  props: ["type", "level", "points"],

  data() {
    return {
      ready: false,
    };
  },

  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 900);
  },

  methods: {
    close() {
      this.ready = false;
      setTimeout(() => {
        this.$emit("close");
      }, 350);
    },

    dismiss() {
      this.ready = false;
      setTimeout(() => {
        this.type == "special_date"
          ? this.$emit("unlock")
          : this.$emit("close");
      }, 350);
    },

    unlock() {
      this.ready = false;
      setTimeout(() => {
        this.$emit("unlock");
      }, 350);
    },

    exchange() {
      this.ready = false;
      setTimeout(() => {
        this.$emit("exchange");
      }, 350);
    },

    exchangeAuto() {
      this.ready = false;
      setTimeout(() => {
        this.$emit("exchangeAuto");
      }, 350);
    },

    exchangeQuiz() {
      this.ready = false;
      setTimeout(() => {
        this.$emit("exchangeQuiz");
      }, 350);
    },

    onCloseImmediatePopup() {
      this.ready = false;
      setTimeout(() => {
        this.$emit("onCloseImmediatePopup");
      }, 350);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/modal.scss";

.modal {
  animation-duration: 0.45s;
}
</style>
