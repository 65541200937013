<template>
  <div class="wrapper" v-if="isNewLogin || !campaign.link">
    <div class="content">
      <h2 class="heading" v-html="campaign.text1"></h2>
      <button @click="redirect">
        วันนี้คุณได้รับ {{ campaign.immediate_points }} คะแนนแล้ว
      </button>
      <p class="bold">{{ campaign.text2 }}</p>
    </div>
  </div>

  <div v-else class="page-loading bg-blue">
    <p>Loading...</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({ calendarInfo: "calendar/calendarInfo" }),
    campaign() {
      return this.calendarInfo.campaign_data;
    },
    isNewLogin() {
      return this.calendarInfo.new_checkin === 1;
    },
    isEnableImmediatePoints() {
      return this.calendarInfo.campaign_data.enable_immediate_points === 1;
    },
  },
  methods: {
    redirect() {
      if (this.campaign.link) window.parent.location = this.campaign.link;
    },
  },
  async created() {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (
      this.isEnableImmediatePoints &&
      !this.isNewLogin &&
      this.campaign.link
    ) {
      this.redirect();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_color";

.wrapper {
  height: calc(100vh - 100px);
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url("~@/assets/images/landing-page-bg.png");

  .content {
    top: 10rem;
    position: relative;
    text-align: center;

    .heading {
      font-size: 32px;
      padding: 0px 3rem;
      font-weight: bold;
    }

    button {
      border: none;
      font-size: 24px;
      padding: 6px 24px;
      border-radius: 8px;
      margin-bottom: 1rem;
      color: $primary-color;
      background-color: white;
    }

    p {
      line-height: 1;
      font-weight: bold;
      padding: 0px 3rem;
      margin-bottom: 0.5rem;

      &.bold {
        font-weight: bold;
      }
    }
  }
}
</style>
