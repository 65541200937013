<template>
  <div class="home">
    <!-- Campaign active -->
    <div v-if="isCampaignActive">
      <!-- Immediate Mode Layout -->
      <LandingPage v-if="isEnableImmediatePoints" />

      <!-- Default with Calendar layout -->
      <div v-else>
        <div class="navbar">
          <span>จำนวนเหรียญสะสม:</span>
          <div class="coin-counter">
            <div class="number">
              {{ userCoins | numberWithCommas }}
            </div>
            <div class="coin"></div>
          </div>
        </div>

        <div class="coin-exchange" v-if="isEnableCoinExchange">
          <div class="block">
            <div class="mini">
              <span>Level 1</span>
              <div class="coin-counter">
                <div class="number">
                  {{ fromCoins(0) | numberWithCommas }}
                </div>
                <div class="coin"></div>
              </div>
            </div>
            <div class="thumb">
              <div class="level level-1" :state="getChestStatus(0)"></div>
              <div class="bg" :class="{ active: isActiveChestBase(0) }"></div>
            </div>
            <div class="point">
              {{ toPoints(0) | numberWithCommas }}
              คะแนน
            </div>
          </div>
          <div class="block">
            <div class="mini">
              <span>Level 2</span>
              <div class="coin-counter">
                <div class="number">
                  {{ fromCoins(1) | numberWithCommas }}
                </div>
                <div class="coin"></div>
              </div>
            </div>
            <div class="thumb">
              <div class="level level-2" :state="getChestStatus(1)"></div>
              <div class="bg" :class="{ active: isActiveChestBase(1) }"></div>
            </div>
            <div class="point">
              {{ toPoints(1) | numberWithCommas }}
              คะแนน
            </div>
          </div>
          <div class="block">
            <div class="mini">
              <span>Level 3</span>
              <div class="coin-counter">
                <div class="number">
                  {{ fromCoins(2) | numberWithCommas }}
                </div>
                <div class="coin"></div>
              </div>
            </div>
            <div class="thumb">
              <div class="level level-3" :state="getChestStatus(2)"></div>
              <div class="bg" :class="{ active: isActiveChestBase(2) }"></div>
            </div>
            <div class="point">
              {{ toPoints(2) | numberWithCommas }}
              คะแนน
            </div>
          </div>
        </div>

        <!-- Calendar -->
        <div class="calendar">
          <vue-calendar
            ref="calendar"
            :events="history"
            :disable="disabledDays"
            :show-limit="1"
            height="400px"
            class="disable-dbl-tap-zoom"
          >
          </vue-calendar>
        </div>
      </div>

      <!-- Transition and Popup -->
      <transition
        :enter-active-class="transitionEnterActiveClass"
        :leave-active-class="transitionLeaveActiveClass"
      >
        <Modal
          v-if="displayLuckyModal"
          :level="level"
          :points="points"
          :type="modalType"
          @close="hideModal"
          @exchangeAuto="exchangeAuto"
          @exchangeQuiz="exchangeQuiz"
          @unlock="getPointSpecialDate"
          @exchange="exchangeCoinsToPoints"
          @onCloseImmediatePopup="onCloseImmediatePopup"
        />
      </transition>

      <!-- Transition and Quiz Popup -->
      <transition
        enter-active-class="animated bounceIn"
        leave-active-class="animated bounceOut"
      >
        <QuizModal v-if="displayQuizModal" :quiz="quiz" @correct="onCorrect" />
      </transition>
    </div>

    <!-- Campaign inactive -->
    <div v-else class="no-active-campaign-found">
      <p>ขออภัย กิจกรรม Daily Login ยังไม่เริ่ม</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { DateTime } from "luxon";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal.vue";
import QuizModal from "@/components/QuizModal.vue";
import LandingPage from "@/views/LandingPage.vue";

export default {
  name: "Home",

  components: { Modal, QuizModal, LandingPage },

  data() {
    return {
      loading: true,

      level: 1,
      points: 0,
      quiz: null,
      // normal_date | special_date | coin_exchange | immediate_point | coin_exchange_auto
      modalType: "normal_date",
      displayQuizModal: false,
      displayLuckyModal: false,
      displayLandingPage: false,
      transitionEnterActiveClass: "animated zoomInDown",
      transitionLeaveActiveClass: "animated zoomOutDown",

      userCoins: `-`,
      didReceivedPoint: false,
    };
  },

  computed: {
    ...mapGetters({ calendarInfo: "calendar/calendarInfo" }),

    isNewLogin() {
      return this.calendarInfo.new_checkin === 1;
    },

    isCampaignActive() {
      return this.calendarInfo.active_campaign === 1;
    },

    isEnableImmediatePoints() {
      return this.calendarInfo.campaign_data.enable_immediate_points === 1;
    },

    history() {
      return this.calendarInfo.history.map(function(h) {
        return {
          classes: h.classes,
          start: new Date(h.year, h.month, h.day),
        };
      });
    },

    isSpecialDate() {
      return this.calendarInfo.receive_points > 0;
    },

    isEnableCoinExchange() {
      return this.calendarInfo.campaign_data.enable_coin_exchange === 1;
    },

    isEnableQuiz() {
      return this.calendarInfo.campaign_data.enable_quiz === 1;
    },

    disabledDays() {
      const start_date = this.calendarInfo.campaign_data.start_date;
      const end_date = this.calendarInfo.campaign_data.end_date;
      return {
        to: new Date(start_date),
        from: new Date(end_date),
      };
    },
  },

  methods: {
    ...mapActions({
      exchange: `calendar/exchange`,
    }),

    fromCoins(index) {
      return this.calendarInfo.campaign_data.coin_exchange_list[index]
        .from_coins;
    },

    toPoints(index) {
      return this.calendarInfo.campaign_data.coin_exchange_list[index]
        .to_points;
    },

    showQuizPopup() {
      if (this.isEnableQuiz && this.enoughCoin(this.level - 1)) {
        // ตรวจสอบก่อนว่าเหรียญเพียงพอต่อการ เล่นตอบคำถามเพื่อแลกแต้มไหม
        this.quiz = this.calendarInfo.quiz;
        this.displayQuizModal = true;
      }
    },

    onCorrect() {
      this.displayQuizModal = false;
      setTimeout(this.openChestByQuiz, 1500);
    },

    hideModal() {
      this.displayLuckyModal = false;

      if (this.isNewLogin && !this.isSpecialDate) {
        setTimeout(() => {
          this.userCoins = this.userCoins + 1;
        }, 1500);
      }

      if (this.isEnableQuiz) {
        setTimeout(this.showQuizPopup, 1500);
        return;
      }

      if (this.calendarInfo.exchange_success === 1) {
        setTimeout(this.autoOpenChest, 1500);
        return;
      }
    },

    exchangeAuto() {
      this.setReceivedPointState();
      this.displayLuckyModal = false;
      setTimeout(this.tellUserToRefreshApp, 1000);

      const vm = this;
      setTimeout(() => {
        const { no_exchange } = vm.calendarInfo.user_data;
        const useCoins = no_exchange > 0 ? vm.fromCoins(no_exchange - 1) : 0;
        vm.userCoins = vm.userCoins - useCoins;
      }, 1500);
    },

    async exchangeQuiz() {
      try {
        const { exchange_step } = this.calendarInfo;
        await this.exchange(exchange_step);

        this.setReceivedPointState();
        this.displayLuckyModal = false;
        setTimeout(this.tellUserToRefreshApp, 1000);

        const vm = this;
        setTimeout(() => {
          const { no_exchange } = vm.calendarInfo.user_data;
          const useCoins = no_exchange > 0 ? vm.fromCoins(no_exchange - 1) : 0;
          vm.userCoins = vm.userCoins - useCoins;
        }, 1500);
        
      } catch (error) {
        console.error(error);
        this.$dialogs.alert(
          error.response ? error.response.data : error.message
        );
      }
    },

    getPointSpecialDate() {
      this.displayLuckyModal = false;

      if (this.calendarInfo.exchange_success === 1) {
        setTimeout(this.autoOpenChest, 1500);
        return;
      }

      if (this.isSpecialDate) {
        setTimeout(this.tellUserToRefreshApp, 1000);
      }
    },

    onCloseImmediatePopup() {
      this.displayLuckyModal = false;
    },

    tellUserToRefreshApp() {
      const options = { okLabel: "ตกลง" };
      this.$dialogs.alert(
        `ขอแสดงความยินดีด้วย กรุณารีเฟรชที่หน้าจอ เพื่อแสดงคะแนนล่าสุดที่คุณมี`,
        options
      );
    },

    showImmediatePointPopup() {
      this.points = this.calendarInfo.receive_points;
      this.modalType = "immediate_point";
      this.displayLuckyModal = true;
    },

    openChestByQuiz() {
      const { exchange_step, exchange_points } = this.calendarInfo;

      this.level = exchange_step;
      this.points = exchange_points;
      this.modalType = "coin_exchange_by_quiz";
      this.displayLuckyModal = true;
    },

    autoOpenChest() {
      const {
        exchange_success,
        exchange_step,
        exchange_points,
      } = this.calendarInfo;

      if (exchange_success === 1) {
        this.level = exchange_step;
        this.points = exchange_points;
        this.modalType = "coin_exchange_auto";
        this.displayLuckyModal = true;
      }
    },

    setReceivedPointState() {
      const vm = this;
      setTimeout(() => {
        vm.didReceivedPoint = true;
      }, 1500);
    },

    setDisplayDate(date) {
      date = DateTime.fromMillis(date.getTime()).setLocale("th-TH");

      this.$refs.calendar.$el.querySelectorAll(
        `.calendar-header > .header-center > .title`
      )[0].innerHTML = `${date.monthLong} ${date.year + 543}`;
    },

    displayPopupCampaignLogin() {
      // enable_immediate_points === 1
      if (this.isEnableImmediatePoints) {
        this.showImmediatePointPopup();
      }
      // enable_immediate_points !== 1
      else {
        this.showCollectCoinPopup();
      }
    },

    showCollectCoinPopup() {
      if (this.isSpecialDate) {
        this.points = this.calendarInfo.receive_points;
        this.modalType = "special_date";
        this.displayLuckyModal = true;
      } else {
        this.modalType = "normal_date";
        this.displayLuckyModal = true;
      }
    },

    getChestStatus(index) {
      // varibles
      const noExchange = this.calendarInfo.user_data.no_exchange;
      const totalCoins = this.calendarInfo.user_data.total_coins;
      const exchangeSuccess = this.calendarInfo.exchange_success;

      if (
        exchangeSuccess == 0 &&
        noExchange >= index + 1 &&
        totalCoins > this.fromCoins(index)
      ) {
        return "open";
      }

      if (
        !this.didReceivedPoint &&
        this.enoughCoin(index) &&
        noExchange <= index + 1
      ) {
        return "close";
      }

      if (noExchange >= index + 1) {
        return "grey";
      }

      if (this.enoughCoin(index) && noExchange < index + 1) {
        return this.isEnableQuiz ? "close" : "open";
      }

      if (!this.enoughCoin(index) && noExchange < index + 1) {
        return "close";
      }
    },

    isActiveChestBase(index) {
      const state = this.getChestStatus(index);
      return state != "close";
    },

    enoughCoin(index) {
      const no_exchange = this.calendarInfo.user_data.no_exchange;

      const sum = this.calendarInfo.campaign_data.coin_exchange_list
        .filter((v, i) => i <= index)
        .filter((v, i) => no_exchange < i + 1)
        .reduce((n, l) => n + l.from_coins, 0);

      return this.calendarInfo.user_data.coins >= sum;
    },

    async exchangeCoinsToPoints() {
      try {
        this.displayLuckyModal = false;
        await this.exchange(this.level);

        setTimeout(this.tellUserToRefreshApp, 1000);
      } catch (error) {
        this.$dialogs.alert(
          error.response ? error.response.data : error.message
        );
      }
    },

    setUserCoins() {
      const exchangeSuccess = this.calendarInfo.exchange_success;
      const { coins, no_exchange } = this.calendarInfo.user_data;

      if (!this.isNewLogin) {
        this.userCoins = coins;
        return;
      }

      switch (no_exchange) {
        case 0:
          this.userCoins = coins - 1;
          break;

        default:
          const index = no_exchange - 1;

          if (exchangeSuccess == 1) {
            this.userCoins = coins + this.fromCoins(index) - 1;
          } else {
            this.userCoins = coins - 1;
          }
          break;
      }
    },
  },

  mounted() {
    this.setDisplayDate(new Date());
    this.$calendar.eventBus.$on("month-changed", this.setDisplayDate);
  },

  async created() {
    await new Promise((resolve) => setTimeout(resolve, 500));

    this.setUserCoins();

    await new Promise((resolve) => setTimeout(resolve, 500));

    if (this.isNewLogin) {
      // show daily login popup
      setTimeout(this.displayPopupCampaignLogin, 500);
    } else {
      this.didReceivedPoint = true;
      // show quiz popup
      setTimeout(this.showQuizPopup, 1500);
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/home.scss";
</style>
