<template>
  <div class="quiz-modal modal-page" :class="{ active: ready }">
    <div class="container hold-in-center">
      <div class="header">กรุณาตอบคำถาม ก่อนรับสิทธิ์เปิดหีบ</div>
      <div class="image-video">
        <img v-if="hasImage" :src="quiz.image_url" />

        <div v-else-if="hasVideo" class="video-container">
          <iframe class="responsive-iframe" :src="quiz.video_url"></iframe>
        </div>
      </div>
      <h4 class="question">{{ question }}</h4>
      <p class="error-message" v-if="showError">ลองตอบคำถามใหม่อีกครั้ง</p>
      <div class="choice-list" :class="{ error: showError }">
        <div
          v-for="n in 4"
          :key="n"
          class="choice"
          :class="{
            selected: +selected === +n,
            'is-empty-null': isEmptyOrNullText(n),
          }"
        >
          <div v-if="quiz[`choice${n}`]"></div>
          <label class="radio radio-gradient">
            <span class="radio__input">
              <input type="radio" name="radio" v-model="selected" :value="n" />
              <span class="radio__control"></span>
            </span>
            <span class="radio__label"> {{ quiz[`choice${n}`] }}</span>
          </label>
        </div>
      </div>
      <div class="footer">
        <button
          class="btn btn-primary"
          @click="submit"
          :disabled="selected === null"
        >
          ยืนยัน
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizModal",

  props: ["quiz"],

  data() {
    return {
      ready: false,
      selected: null,
      showError: false,
      choiceStatus: "default",
    };
  },

  watch: {
    selected(n, o) {
      this.showError = false;
    },
  },

  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 900);
  },

  computed: {
    hasImage() {
      return this.quiz && this.quiz.image_video == "image";
    },

    hasVideo() {
      return this.quiz && this.quiz.image_video == "video";
    },

    question() {
      return this.quiz ? this.quiz.question : "";
    },
  },

  methods: {
    isEmptyOrNullText(n) {
      const choiceText = this.quiz[`choice${n}`];
      return choiceText === null || choiceText == "";
    },

    submit() {
      if (this.selected === this.quiz.correct_choice) {
        // this.ready = false;
        this.$emit("correct");
      } else {
        this.showError = true;
      }
    },

    close() {
      // this.ready = false;
      setTimeout(() => {
        this.$emit("close");
      }, 350);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/quiz.modal.scss";

.modal {
  animation-duration: 0.45s;
}
</style>
