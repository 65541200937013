import { checkin, exchange } from "@/api/http";

export default {
  namespaced: true,

  state: {
    calendar: null,
  },

  getters: {
    calendarInfo(state) {
      return state.calendar;
    },
  },

  actions: {
    async checkin({ commit }) {
      const { data } = await checkin();
      commit("SET_CALENDAR_INFO", data);
    },
    async exchange({ commit }, params) {
      const { data } = await exchange(params);
      if (data.exchange_success === 1) {
        commit("SET_EXCHANGE_COIN_DATA", data);
      } else {
        throw new Error(`EXCHANGE_SUCCESS !== 1`);
      }
    },
  },

  mutations: {
    SET_CALENDAR_INFO(state, payload) {
      state.calendar = payload;
    },

    SET_EXCHANGE_COIN_DATA(state, payload) {
      state.calendar.time = payload.time;
      state.calendar.history = payload.history;
      state.calendar.user_data = payload.user_data;
      state.calendar.campaign_data = payload.campaign_data;
      state.calendar.exchange_success = payload.exchange_success;
    },
  },
};
